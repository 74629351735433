window.google = window.google || {};

google.productpageevents = {
  constants: {
    findADealerButtonClass: '.tag-manager-find-a-dealer-btn',
    colorVariationLinkClas: '.tag-manager-color-variation',
    userManualLinkClass: '.tag-manager-user-manual',
    colorvariationEvent: 'colorClick',
    userManualEvent: 'productPDFDownload',
    readMoreButtonClass: '.car-fit-slider-btn-text',
    sparePartButtonClass: '.tag-manager-spare-part',
  },

  setup: function () {
    try {
      google.productpageevents.addOnClickEventToFindADealerButton();
      google.productpageevents.addOnClickEventToColorvariationLink();
      google.productpageevents.addOnClickEventUserManualLink();
      google.productpageevents.addOnClickEventReadMore();
      google.productpageevents.addOnClickEventSparePartBtn();
    } catch (error) {}
  },

  addOnClickEventToFindADealerButton: function () {
    try {
      document.querySelectorAll(google.productpageevents.constants.findADealerButtonClass).forEach((item) => {
        item.addEventListener('click', () => {
          google.productpageevents.addFindADealerToDataLayer();
        });
      });
    } catch (error) {}
  },

  addOnClickEventToColorvariationLink: function () {
    try {
      document.querySelectorAll(google.productpageevents.constants.colorVariationLinkClas).forEach((item) => {
        item.addEventListener('click', () => {
          google.productpageevents.addColorVariationToDataLayer(item);
        });
      });
    } catch (error) {}
  },

  addOnClickEventUserManualLink: function () {
    try {
      document.querySelectorAll(google.productpageevents.constants.userManualLinkClass).forEach((item) => {
        item.addEventListener('click', () => {
          google.productpageevents.addUserManualEventToDataLayer(item);
        });
      });
    } catch (error) {}
  },

  addOnClickEventReadMore: function () {
    try {
      waitForElm(google.productpageevents.constants.readMoreButtonClass).then((elm) => {
        elm.addEventListener('click', () => {
          google.productpageevents.addEventReadMoreToDataLayer(elm);
        });
      });
    } catch (error) {}
  },

  addOnClickEventSparePartBtn: function () {
    document.querySelectorAll(google.productpageevents.constants.sparePartButtonClass).forEach((item) => {
      item.addEventListener('click', () => {
        try {
          google.productpageevents.addSparePartEventToDataLayer();
        } catch (error) {}
      });
    });
  },

  addFindADealerToDataLayer: function () {
    try {
      var values = google.productpageevents.getFindADealerValues();
      google.tagmanager.add(values);
    } catch (error) {}
  },

  addColorVariationToDataLayer: function (link) {
    try {
      var values = google.productpageevents.getColorvariationValues(link);
      google.tagmanager.add(values);
    } catch (error) {}
  },

  addUserManualEventToDataLayer: function (link) {
    try {
      var values = google.productpageevents.getUserManualValues(link);
      google.tagmanager.add(values);
    } catch (error) {}
  },

  addEventReadMoreToDataLayer: function (link) {
    try {
      var values = google.productpageevents.getReadMoreValues(link);
      google.tagmanager.add(values);
    } catch (error) {}
  },

  addSparePartEventToDataLayer: function () {
    try {
      var values = google.productpageevents.getSparePartValues();
      google.tagmanager.add(values);
    } catch (error) {}
  },

  getColorvariationValues: function (link) {
    try {
      var color = link.getAttribute('title');
      var url = link.getAttribute('style').replace("background-image: url('", '').replace("');", '');
      var values = {
        colorSelected: color,
        urlOfColor: url,
        event: google.productpageevents.constants.colorvariationEvent,
      };
      return values;
    } catch (error) {}
  },

  getFindADealerValues: function () {
    var values = {
      buttonName: 'findDealer',
      event: 'findDealerClick',
    };
    return values;
  },

  getUserManualValues: function (link) {
    try {
      var type = link.dataset.assettype;
      var values = {
        typeOfDownload: type,
        pdfName: link.getAttribute('href'),
        event: google.productpageevents.constants.userManualEvent,
      };
      return values;
    } catch (error) {}
  },

  getReadMoreValues: function () {
    var values = {
      event: 'fit-tip-click',
      buttonName: 'readMore',
    };
    return values;
  },

  getSparePartValues: function () {
    var values = {
      event: 'sparePartClick',
    };
    return values;
  },

  init: function () {
    try {
      google.productpageevents.setup();
      var productPage = document.querySelector('#product-view');
      if (productPage) google.tagmanager.events.productPageViewed(productPage);
    } catch (error) {}
  },
};

function waitForElm(selector) {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

if (document.readyState === 'complete' || document.readyState === 'loaded') {
  google.productpageevents.init();
} else {
  window.addEventListener('DOMContentLoaded', () => {
    google.productpageevents.init();
  });
}
